// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "src/application.scss";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import ClipboardJS from 'clipboard';

document.addEventListener("turbolinks:load", function() {
  const clipboard = new ClipboardJS('#copyButton');

  clipboard.on('success', function(e) {
    const confirmation = document.getElementById('copyConfirmation');
    confirmation.style.display = 'inline';  // Show the "Copied!" message

    setTimeout(function() {
      confirmation.style.display = 'none';  // Hide the "Copied!" message after 2 seconds
    }, 2000);

    e.clearSelection();
  });
});

